/*! jQuery UI - v1.11.2 - 2014-10-17
* http://jqueryui.com
* Includes: core.css, menu.css, selectmenu.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Verdana%2CArial%2Csans-serif&fwDefault=normal&fsDefault=1.1em&cornerRadius=5px&bgColorHeader=2191c0&bgTextureHeader=gloss_wave&bgImgOpacityHeader=75&borderColorHeader=4297d7&fcHeader=eaf5f7&iconColorHeader=d8e7f3&bgColorContent=fcfdfd&bgTextureContent=inset_hard&bgImgOpacityContent=100&borderColorContent=a6c9e2&fcContent=222222&iconColorContent=0078ae&bgColorDefault=0078ae&bgTextureDefault=glass&bgImgOpacityDefault=45&borderColorDefault=77d5f7&fcDefault=ffffff&iconColorDefault=e0fdff&bgColorHover=79c9ec&bgTextureHover=glass&bgImgOpacityHover=75&borderColorHover=448dae&fcHover=026890&iconColorHover=056b93&bgColorActive=6eac2c&bgTextureActive=gloss_wave&bgImgOpacityActive=50&borderColorActive=acdd4a&fcActive=ffffff&iconColorActive=f5e175&bgColorHighlight=f8da4e&bgTextureHighlight=glass&bgImgOpacityHighlight=55&borderColorHighlight=fcd113&fcHighlight=915608&iconColorHighlight=f7a50d&bgColorError=e14f1c&bgTextureError=gloss_wave&bgImgOpacityError=45&borderColorError=cd0a0a&fcError=ffffff&iconColorError=fcd113&bgColorOverlay=aaaaaa&bgTextureOverlay=flat&bgImgOpacityOverlay=75&opacityOverlay=30&bgColorShadow=999999&bgTextureShadow=flat&bgImgOpacityShadow=55&opacityShadow=45&thicknessShadow=0px&offsetTopShadow=5px&offsetLeftShadow=5px&cornerRadiusShadow=5px
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-front {
  z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}


/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}


/* Misc visuals
----------------------------------*/
.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none;
  height: 50%
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  cursor: pointer;
  min-height: 0; /* support: IE7 */
  padding: 4px 10px 4px 17px;
}

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px;
}


/* icon support */
.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item {
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0;
}

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  /* Support: IE7 */
  overflow-x: hidden;
  padding-bottom: 1px;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  padding: 2px 0.4em;
  margin: 0.5em 0 0 0;
  height: auto;
  border: 0;
}

.ui-selectmenu-open {
  display: block;
  max-width: 408px;
}

.ui-selectmenu-button {
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
}

.ui-selectmenu-button span.ui-icon {
  right: 12px;
  left: auto;
  margin-top: 0;
  position: absolute;
  top: 0
}

.ui-selectmenu-button span.ui-selectmenu-text {
  text-align: left;
  padding: 1px 15px 4px 4px;
  display: block;
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Component containers
----------------------------------*/

.ui-widget-content {
  border: 1px solid @CouleurBorderBlueLight;
  background: @CouleurBgWhite;
  color: @CouleurBlueDark;
  overflow-y: auto;
  max-height: 350px;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid @CouleurBorderGreyTitle;
  background: @CouleurBgWhite 50% 50% repeat-x;
  font-weight: normal;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid @CouleurBorderGreyTitle;
  background: @CouleurBgBlueLight 50% 50% repeat-x;
  font-weight: normal;
  color: @CouleurBlueDark;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35); /* support: IE8 */
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35); /* support: IE8 - See #6059 */
}


/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-state-default .ui-icon,
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button .ui-icon {
  background-image: url("../images/bg_select_vert.png") !important;
  width: 29px !important;
  height: 38px !important;
}


/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 2px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 2px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 2px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 2px;
}
