.tag-expander.global-selector{
	display: inline-block;
    padding-right: 10px;
    cursor: pointer;
	.expander-container.codificationTableau.material-icons{
		.expander-icon:before {
			content:"add_circle_outline";
		}
	}
}
.tag-expander.target-opened.global-selector{
	.expander-container.codificationTableau.material-icons{
		.expander-icon:before {
			content:"remove_circle_outline";
		}
	}
}

.tab-niv-final > .tag-expander.global-selector  {
	display: none;
}

.tab-lbu-simple > .tag-expander.global-selector  {
	display: none;
}

.tab-niv-cadre > .tag-expander.global-selector  {
	display: none;
}

.tab-niv-ssj > .tag-expander.global-selector  {
	display: none;
}

.basic-table .blocCriteres  {
	table.tableau-hover, table.table-expander {
		thead th > a {
			pointer-events: none;
		}
		tbody tr {
			background: @CouleurBgWhite;
			th.column-header {
				color: @CouleurEnteteTable;
	          	text-transform: uppercase;
	          	font-size: 0.875em;
	          	font-weight: 700;
	          	padding: 15px;
	          	text-align: left;
	          	border: 1px solid @CouleurBorderGreyTitle;
	          	position: relative;
	      	}
			th:nth-child(2), td:first-child, th:first-child {
			    position: relative;			    
			}		
			div.wrapper {
				    display: inline-grid;
					vertical-align: middle;
				> span {
					text-overflow: ellipsis;
		  			overflow: hidden; 
		  			white-space: nowrap;
				}
				> .libelle-hover {
					display: none;
				}
			}
			div.wrapper:hover > .libelle-hover {
			    top: 36px;
			    display: block;
			    text-align: center;
			    padding: 4px;
			    border-radius: 6px;
			    position: absolute;
			    z-index: 1;
			    font-size: 12px;
			    font-family: 'roboto';
			    font-weight: 500;
			    background-color: #555;
				color: @CouleurBgWhite;
				text-transform: initial;
			}
			 .tab-niv1 {
				background: @CouleurExpanderBackgroundNiv1;
			}
			 .tab-niv2 {
				background: @CouleurExpanderBackgroundNiv2;
			}
			 .tab-lbu-simple{
				background: @CouleurLigneBudgSimple;
			}
			 .tab-lbu-cadre{
				background: @CouleurLigneBudgCadre;
			}
			 .tab-lbu-ssj{
				background: @CouleurLigneBudgSSJ;
			}
			td:first-child > div.wrapper, th:first-child > div.wrapper, .tab-niv3:first-child > div.wrapper {
				width: calc(100% - 34px);
			}
			.tab-niv3:first-child>  span:nth-child(2) {
				padding-left: 55px;
			}
			th.centre {
				text-align: center;
	      	}
			.tab-niv2.tab-niv-final:first-child > span{
				padding-left: 48px;
			}
			.tab-niv1.tab-niv-final:first-child > span{
				padding-left: 35px;
			}
			.tab-niv1.tab-niv-final:first-child	> div.wrapper {
			    padding-left: 34px;	
			}
			.tab-niv2.tab-niv-final:first-child	> div.wrapper {
			    padding-left: 33px;	
			}
			.tab-niv1:first-child > div.wrapper:hover > .libelle-hover {
				left: 43px;
			}
			.tab-niv2:first-child > div.wrapper:hover > .libelle-hover {
				left: 63px;
			}
			.tab-niv3:first-child > div.wrapper:hover > .libelle-hover  {
				left: 78px;
			}
			.tab-niv4:first-child > div.wrapper:hover > .libelle-hover {
				left: 86px;
			}
			.tab-niv2:first-child > div.wrapper > span {
				padding-left: 20px;
			}
			
			.tab-niv3:first-child > div.wrapper > span {
				padding-left: 36px;
			}
			
			.tab-niv3.tab-niv-final:first-child > div.wrapper {
				width: 100%;
				> span{
					padding-left: 70px;
				}
			}
			.tab-niv4.tab-niv-final:first-child > div.wrapper {
				width: 100%;
				> span{
					padding-left: 86px;
				}
			}
	        .tab-lbu-ssj:first-child > div.wrapper {
	            padding-left: 33px;    
	        }
		}
	}
}
.basic-table .blocCriteres table.tableau-hover tbody tr{
	 td:nth-child(2), td:nth-child(3) {
		position: relative;
	}
}