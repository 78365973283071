//icons font
	
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  padding: 2px 6px 2px 6.06px;
  color: @CouleurMainBlue; /* Couleur par d�faut*/

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
	&.md36 { 
		font-size:36px;
	}
	&.table{
		padding: 0px;
		&:hover{
	       	color: @CouleurGreen;
			cursor: pointer;				
	       	}
	}
	&.grey { 
		color: #5E6D81; 
	}
	&.green {
		color : @CouleurGreen;
		}
	&.logout {
		color : @Error-border;
		font-size : 36px;
		}
	&.toolbar {
		color : @CouleurBgWhite;
	}	
}
.toggle_off, .toggle_on{
	font-size: 34px;
	line-height: 24px;
}
.toggle_on{
	color: @CouleurGreen;
}
.toggle_off{
	color: @CouleurRed;
}
