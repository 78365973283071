/*home page*/
.accueil{
  margin-top: 0;
}

.dossierTODO{
	padding: 0px;
	margin: 0px;
	list-style-type: none;	
	margin-left: 15px;	
}

.dossierTODO a{
	color : black;
	text-decoration: none;
}

.dossierTODO .boutons-centre a{
	color : white;
}