.titre-entete-toolbar{
	display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
}
.titre-entete{
	flex: 1;
}
.toolbar {
	position: relative;
    padding-top: 6px;
    padding-right: 16px;
    background: @CouleurBgWhite;
    padding-left: 16px;
	ul {
		padding-left: 0px;
		list-style-type: none;
	    overflow: hidden;
	    margin: 0;
	    li {
	    	float: left;
	    	width: fit-content;
			border-right: 1px solid @CouleurBorderGreyTitle;
		    margin-right: 5px;
		    padding-right: 5px;
	    	a {
	    		display: block;
			    text-align: center;
			    text-decoration: none;
			    }
	    	.material-icons {
			    	position:inherit;
			    	padding: 2px 2px;
			    	&.on, &.active, &:hover{
          				color: @CouleurBgGreen;
          			}
	    	}
	    	.toggle_on:hover {
	    		color: @CouleurBgGreen;
	    	}
	    	.toggle_off:hover{
                color: @CouleurRed;
			}
        }
		li:last-child{
			border-right: none;
			margin-right: unset;
			padding-right: unset;
		}
        .grise-toolbar{
    	   cursor: unset;
        	a{
	    	pointer-events: none;
        		i{
	        		color: @CouleurBorderLightGrey;
      			    vertical-align: top;
        		}
        		i:hover{
        			color: @CouleurBorderLightGrey;	        			
        		}
        	}
        }
	 }
}
