//champs texte
input {
  border: 1px solid @CouleurBorderGrey;
  background-color: @CouleurBgWhite;
  font-size: 0.938em;

}

.kss_montant_kss, .kss_montant_diff_kss {
  input.kss_montant_kss, input.kss_montant_diff_kss {
    margin-right: 0;
  }
}

.file-input {
  border: 0px;
  background-color: @CouleurBgGrey;
}

//select
.ui-selectmenu-menu.ui-front {
  background: @CouleurBgWhite;
  border: 1px solid @CouleurBorderGrey;
}

//autocomplete search
.combosearch {
  .container {
    ul {
      border: 1px solid @CouleurBorderGrey;
      background-color: @CouleurBgWhite;
      font-size: 0.938em;
      margin: 0;
      display: inline-block;
      width: auto !important;
      min-height: 100px;
      padding: 0;
      border-top: 0;
      max-height: 200px;
      overflow-y: scroll;

      li {
        list-style: none;
        color: @CouleurMainBlue;
        font-size: 1.05em;
        font-weight: 400;
        padding: 4px 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: @CouleurBgBlueLight 50% 50% repeat-x;
        }
      }
    }

    .yui-ac-ft {
      margin-top: 7px;
      color: @CouleurBlueDark;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.938em;
    }


  }

  .custom-combobox {
    position: relative;
    display: inline-block;
  }

  .custom-combobox-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    padding: 0;
  }

  .custom-combobox-input {
    margin: 0;
    padding: 5px 10px;
  }

  .ui-autocomplete-loading {
    background: url("../images/icon/ui-anim_basic_16x16.gif") right center no-repeat;
  }

}

.ui-menu .ui-menu-item {
  &:hover {
    background: @CouleurBgBlueLight 50% 50% repeat-x !important;
  }
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
  text-decoration: none !important;
  background: none !important;
  cursor: pointer !important;
  color: @CouleurBlack !important;
  border: none !important;
}


