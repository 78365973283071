.popin-background {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.popin.popin-larger {
  width: 65%;
}

.popin {
	position: relative;
    background-color: @CouleurBgGreyLight;
    top: 20%;
    margin: auto;
    padding: 20px;
    width: 50%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    min-width: 700px;
	.file-input {
		background-color: @CouleurBgWhite;
	}
	table.without-border { 
		.error {
			margin-top: 0;
		}
		> tbody > tr > td > span.error{
			background-color: @CouleurBgWhite !important;
			border-left: none !important;
		}
	}
	 .error {
	    padding: 23px;
	    border-radius: 6px;
	    text-align: left;
	    border-left: 6px solid @Error-border !important;
	    background-color: @Error-background !important;
	    margin-left: 0;
	    margin-top: 10px;
		th {
			vertical-align: baseline;
		}
		td{
			white-space: normal;
		}
	}
	td.kss_montant_kss, td.kss_montant_diff_kss {
        	white-space: nowrap;
    	}

	  .blocCriteres{
  	
    table{
      background: @CouleurBgWhite;
     }
      
      thead{
        th{
          color: @CouleurEnteteTable;
          text-transform: uppercase;
          font-size: 0.875em;
          font-weight: 700;
          padding: 15px;
          text-align: left;
          border: 1px solid @CouleurBorderGreyTitle;
          position: relative;

          a{
            font-size:1em;
            text-decoration: none;
            font-weight: 700;
            display: inline-block;
    		width: 100%;
            
          }
          
          &.horstable{
            background: @CouleurBgGrey;
            border: 0;
            text-align: right;
            text-transform: capitalize;
            font-weight: 700;
            padding: 5px 0 15px 0;
            
            .left {
            	float: left;
            }
            .right {
            	float: right;
				a{
					display: inline;
				}
            }
          }
        }
      }
      tbody{
        tr{
          background: @CouleurContentTable;
          &.odd{
            background: @CouleurBgWhite;
          }
		  .kss_nombre_dec_kss, .kss_montant_kss, .kss_montant_diff_kss {
			text-align: right;
		}
          td, th{
            text-align: left;
            max-width: 100%;
            border:1px solid @CouleurBorderGreyTitle;
            font-size: 0.875em;
            padding: 11px;
            .material-icons{
            	text-align: center;
			    width: 100%;
			    padding: 0px;
            }
          }
		  td.center{
			text-align: center;
		  }	
		  td.right{
			text-align: right;
			span{
				margin-right: 0;
			}
		  }	
        }
      }
      //empty table
      &.list-empty {
        margin-top: 21px;
        tr th {
          border: 0!important;
          background: @CouleurBgWhite;
        }
      }
      &.tab-ajout-content{
        margin-top: 20px;
        margin-bottom: 20px;
        tr{
          background: @CouleurBgWhite;
          td{
            border: 0;
            padding: 11px 21px;
          }
        }
      }
    }
  }

#signature {
	.error{
			white-space: nowrap;
		}
	th {
		text-align: left;
		width: auto;
	}
	table.lieu-libelle {
		padding-bottom: 0;
		th {
			width: 43px;
		}
	}
	table.signature{
		padding-top: 11px;
	}
}


.titre-popin {
	text-align: left;
    color: @CouleurBtnBlue;
    font-size: 1.375em;
    font-weight: 700;

  }