.validated-bloc-etat{
  padding: 23px;
  border-radius: 6px;
  text-align: left;
  background-color: @CouleurValidAttenuated;
  border-left: 6px solid @CouleurBorderGreen;
  margin-top: 10px;
  }
  
.incomplete-bloc-etat{
  padding: 23px;
  border-radius: 6px;
  text-align: left;
  background-color: @CouleurIncompleteAttenuated;
  border-left: 6px solid @CouleurIncomplete;
  margin-top: 10px;
  }
  
 .titre-bloc-etat {
   text-align: left;
   color: @CouleurBtnBlue;
   font-size: 1em;
   font-weight: 700;
   padding-left: 15px;
   }

.titre-bloc-modalite {
   text-align: left;
   color: @CouleurBtnBlue;
   font-size: 1em;
   font-weight: 700;
   padding-left: 15px;
   margin-top: 20px;
   }
 
 .bloc-message-etat {  
   margin-left: 16px;
   margin-top: 20px;
   font-size: 1.1em;
 }
 .neutre-bloc-etat{
 	padding: 10px 23px;
    border-radius: 6px;
    border-left: 6px solid @CouleurNeutre;
    margin-top: 10px;
    width: 55%;
    }
 .warning-bloc-etat{
  padding: 23px;
  border-radius: 6px;
  text-align: left;
  background-color: @CouleurWarningAttinuated;
  border-left: 6px solid @CouleurWarning;
  margin-top: 10px;
  }