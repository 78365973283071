.footer{
	background: @CouleurBgWhite;
  	padding: 30px 20px;
  .version{
    display: inline-block;
    color: @CouleurMainBlue;
    font-size: 0.875em;
    text-transform: uppercase;

  }
  .area-logos{
    float: right;
  }
  a{
    margin-right: 24px;
	}
}
