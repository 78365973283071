.area-search{
  width: 875px;
  margin: 0 auto;
  padding: 24px 0 24px 80px;
  a, span{
    color:@CouleurMainBlue;
  }
  .label-search{
    color: @CouleurMainBlue;
    font-size: 1.625em;
    float: left;
    margin-right: 34px;
  }
  .champs-search{
    float: left;
    margin-right: 27px;
    label{
      display: none;
    }
    .input{
      input{
        padding: 12px;
        border-radius: 20px;
        border: 1px solid @CouleurBorderGreyTitle;
        float: left;
        width: 411px;
        outline: none;
      }
      a{
        margin-left: -36px;
      }
    }
  }
  .acces-liste{
    float: left;
    padding-top: 11px;
    a{
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.750em;
      color:@CouleurGreyDate;
      text-decoration: none;
    }
  }
  //page liste projets
  &.liste-projet{
    .champs-search{
      float: none;
    }
  }
}