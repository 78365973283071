.entete {
  background: @CouleurBgWhite;

  .header {
    padding: 20px 30px 20px 30px;
    width: 1220px;
    margin: 0 auto;
    position: relative;

    .environnement.developpement, .environnement.recette, .environnement.preproduction, .environnement.formation {
      text-align: center;
      font-weight: 900;
      font-size: 1.625em;
    }

    .environnement.developpement {
      color: @ColorEnvBackgroundDeveloppement;
    }

    .environnement.recette {
      color: @CouleurGreen;
    }

    .environnement.recette.interne {
      color: @ColorEnvBackgroundRecetteInterne;
    }

    .environnement.recette.interne.\32 {
      color: @ColorEnvBackgroundRecetteInterne2;
    }

    .environnement.preproduction {
      color: @ColorEnvBackgroundPreproduction;
    }

    .environnement.formation {
      color: @ColorEnvBackgroundFormation;
    }

    .environnement.validation {
      color: @ColorEnvBackgroundValidation
    }

    span {
      color: @CouleurTxtMenu;
    }

    .logo-container {
      margin-right: 64px;
    }

    //menu en haut de page
    .navigation {
      ul {
        padding: 0;

        li {
          display: inline-block;
          float: none;

          span {
            color: @CouleurTxtMenu;
          }

          margin-right: 35px;
          padding: 7px;

          a {
            text-transform: uppercase;
            color: @CouleurMainBlue;
            font-size: 0.875em;
            text-decoration: none;
          }

          &.actif {
            a {
              font-weight: 700;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            background: @CouleurBgMenu;
            transition: color .2s cubic-bezier(.65, .4, .29, 1.01), background .2s cubic-bezier(.65, .4, .29, 1.01), opacity .2s cubic-bezier(.65, .4, .29, 1.01);

            a {
              color: @CouleurWhite;
            }
          }

          &.inactif.noncliquable:hover {
            background: inherit;
            cursor: inherit;

            a {
              color: @CouleurMainBlue;
            }
          }
        }
      }
    }

    .info-connexion {
      .utilisateur-nom {
        background: url("../images/icon/icone-user-compte.png") no-repeat;
        display: block;
        height: 32px;

        a {
          margin-left: 37px;
          padding-top: 5px;
          display: inline-block;

          &:after {
            content: "";
            background: url("../images/icon/icone-chevron-down.png");
            width: 8px;
            height: 7px;
            margin-left: 7px;
            display: inline-block;
          }
        }

        .label-user {
          margin-left: 37px;
          padding-top: 5px;
          display: inline-block;
          font-size: 0.875em;
          font-weight: 500;
          color: @CouleurTxtMenu;
        }
      }

      .left-area {
        padding-right: 15px;
        border-right: 1px solid @CouleurBorderGreyTitle;
        float: left;
      }

      .right-area {
        margin-left: 0px;

        .area-deconnexion > a > i {
          vertical-align: text-bottom;
        }
      }

      .left-area, .right-area, #middle-area {
        display: inline-block;
      }

      //icone deconnexion
      .area-deconnexion a {
        width: 25px;
        height: 27px;
        display: inline-block;
      }
    }

    .logo-container, .navigation {
      float: left;
    }

    .info-connexion {
      float: right;
    }
  }
}

#middle-area {
  border-right: 1px solid @CouleurBorderGreyTitle;
}

#icon-guide {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  font-size: 49px;
  padding: 2px;
}

#icon-guide:before {
  content: "\e90a";
  color: @CouleurBtnBlue;
}

#icon-tutoriel {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  font-size: 49px;
  padding: 2px;
}

#icon-tutoriel:before {
  content: "\e90e";
  color: @CouleurBtnBlue;
}

span.spinner {
  background-color: transparent;
  background-image: url("../images/icon/spinner.gif");
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 22px;
  position: relative;
  vertical-align: middle;
  display: inline-block;
}
