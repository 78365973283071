/**
  * Resume de ce fichier:
  * Fonts Available ?


  #roboto
    Included:
      300 -> light
      400 -> normal
      500 -> medium
      600 -> semi bold
      700 -> bold

   */

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-regular-webfont.ttf');

  src: url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
  url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-regular-webfont.woff') format('woff'),
  url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}


@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-medium-webfont.ttf');

  src: url('../fonts/roboto-medium-webfont.ttf') format('truetype'),
  url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-medium-webfont.woff') format('woff'),
  url('../fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
  font-weight: 500;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-bold-webfont.ttf');

  src: url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
  url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-bold-webfont.woff') format('woff'),
  url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
  font-weight: 700;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-light-webfont.ttf');

  src: url('../fonts/roboto-light-webfont.ttf') format('truetype'),
  url('../fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-light-webfont.woff') format('woff'),
  url('../fonts/roboto-light-webfont.svg#robotolight') format('svg');
  font-weight: 300;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

/*icone font*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/icons-svg/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('../fonts/icons-svg/MaterialIcons-Regular.woff2') format('woff2'),
  url('../fonts/icons-svg/MaterialIcons-Regular.woff') format('woff'),
  url('../fonts/icons-svg/MaterialIcons-Regular.ttf') format('truetype');

  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

/*icone menu gauche*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons-svg/icomoon.eot?v503');
  src: url('../fonts/icons-svg/icomoon.eot?v503#iefix') format('embedded-opentype'),
       url('../fonts/icons-svg/icomoon.ttf?v503') format('truetype'),
       url('../fonts/icons-svg/icomoon.woff?v503') format('woff'),
       url('../fonts/icons-svg/icomoon.svg?v503 #icomoon') format('svg');
  font-weight: normal;
  font-style: normal;

  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

/*icone menu administration*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons-svg/icomoon-admin.eot?v430');
  src: url('../fonts/icons-svg/icomoon-admin.eot?v430#iefix') format('embedded-opentype'),
  url('../fonts/icons-svg/icomoon-admin.ttf?v430') format('truetype'),
  url('../fonts/icons-svg/icomoon-admin.woff?v430') format('woff'),
  url('../fonts/icons-svg/icomoon-admin.svg?v430#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

/*icone menu pilotage*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons-svg/icomoon-pilotage.eot?v510');
  src: url('../fonts/icons-svg/icomoon-pilotage.eot?v510#iefix') format('embedded-opentype'),
       url('../fonts/icons-svg/icomoon-pilotage.ttf?v510') format('truetype'),
       url('../fonts/icons-svg/icomoon-pilotage.woff?v510') format('woff'),
       url('../fonts/icons-svg/icomoon-pilotage.svg?v510#icomoon-pilotage') format('svg');
  font-weight: normal;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}

/*icone menu ordonnancement*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons-svg/icomoon-ordonnancement.eot?v503');
  src: url('../fonts/icons-svg/icomoon-ordonnancement.eot?v503') format('embedded-opentype'),
       url('../fonts/icons-svg/icomoon-ordonnancement.ttf?v503') format('truetype'),
       url('../fonts/icons-svg/icomoon-ordonnancement.woff?v503') format('woff'),
       url('../fonts/icons-svg/icomoon-ordonnancement.svg?v503#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  -fs-pdf-font-embed: embed;
  -fs-pdf-font-encoding: Identity-H;
}