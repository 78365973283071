.hidden{
	display: none;
}

.button.maj-lignes{
	width: 186px;
    display: flex;
    margin-left: auto;
    margin-right: 0;
}

.blocCriteres > table tbody tr th input, .blocCriteres .table-tableau table.ventilation tbody tr th input {
	width: -webkit-fill-available;
}
.fixed{
	position: fixed;
}
table tr{
	th textarea {
		font-size: 1em;
	    line-height: normal;
	    color: @CouleurMainBlue;
	    margin: 0;
	    font-family: 'roboto';
	    font-weight: normal;
	    background: @CouleurBgGrey;
		vertical-align: middle;
	}
	th.tab-lbu-cadre textarea {
		width: 87%;
	}	
	th.tab-niv-final.tab-lbu-cadre textarea, th textarea {
    	width: 99%;
	}
}
.table-expander.ventilation{
	.tab-lbu-cadre  textarea{
		max-width: calc(100% - 40px);
	}
	.tab-niv-final.tab-lbu-cadre textarea{
		max-width:unset;
	}
} 

.basic-table .blocCriteres table.table-expander tbody tr {
	th.tab-niv-final.tab-lbu-cadre.simulation:first-child > .wrapper,th.simulation.tab-lbu-simple:first-child > .wrapper {
		width: 100%;
	}
}