.nav-and-content{
	background: @CouleurBgGrey;
  width: 1279px;
  min-height: 600px;
  margin: 0 auto;
  display: table;
  .menu-gauche{
    display: table-cell;
  }
  .menu-gauche-deroulant{
    background: @CouleurBtnBlue;
    color: @CouleurWhite;
    //float: left;
    display: table-cell;
    height: 100%;
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        border-bottom: 1px solid @CouleurBorderMenu;
        text-align: center;
        a{
          position: relative;
          padding: 20px;
          cursor: pointer;
          display: inline-block;
        }
        &.on, &.active, &:hover, &.active.noncliquable:hover{
          background: @CouleurBgGreen;
        }
		&.noncliquable:hover{
			background: inherit;
			cursor: auto;
			a{
				cursor: auto;
			}
		}
        .item-img{
          display: inline-block;
          position: relative;
          font-size: 38px;
          &.inactive-link{
            i:before{
              opacity: 0.4;
            }
          }
        }
        .small-icon{
          position: absolute;
          right: 0;
          top: 48px;
          left: 22px;
          border-radius: 20px;
          i{
            border-radius: 8px;
            font-size: 11px;
            padding: 3px;
            &.icon-checked{
              background: @CouleurBtnBlue;
            }
            &.icon-points{
              background: @CouleurBgWhite;
              font-size: 8px;
              display: inline-block;
              width: 12px;
              height: 8px;
              padding-left: 0;
              padding-top: 5px
            }
          }
        }
      }
      &.liste-modules-invariables{
        border-top: 2px solid @CouleurBorderWhite;
      }
    }
  }
  .hide-item-menu{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
  }
  .blocLisere, .blocCriteres, .button_bar {
    margin-left: 12px;
  }
  .contenu-page{
    padding-bottom: 33px;
    margin-left: 80px;
    padding-left: 0;
    padding-top: 0px;
    vertical-align: top;
  }
}

/*icons font*/
[class^="item-img"], [class^="small-icon"]{
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  i{
    font-weight: 400;
    font-style: normal;
  }
}

.icon-checked:before {
  content: "\e905";
  color: @CouleurWhite;
}
.icon-points:before {
  content: "\e906";
}
.icon-bitcoin:before {
  content: "\e902";
  color: @CouleurWhite;
}
.icon-clock:before {
  content: "\e904";
  color: @CouleurWhite;
}
.icon-contract:before {
  content: "\e903";
  color: @CouleurWhite;
}
.icon-folder:before {
  content: "\e907";
  color: @CouleurWhite;
}
.icon-group:before {
  content: "\e908";
  color: @CouleurWhite;
}
.icon-handshake:before {
  content: "\e909";
  color: @CouleurWhite;
}
.icon-card:before {
  content: "\e900";
  color: @CouleurWhite;
}
.icon-tools:before {
  content: "\e901";
  color: @CouleurWhite;
}
.icon-bilan_execution:before {
  content: "\e90b";
  color: @CouleurWhite;
}
.icon-chat:before {
  content: "\e90c";
  color: @CouleurWhite;
}
.icon-document-checked:before {
  content: "\e998";
  color: @CouleurWhite;
}

.icon-box:before {
  content: "\e999";
  color: @CouleurWhite;
}
.blocCriteres > table.parAnnee tbody tr td {
	input,.ui-selectmenu-button{
		width: 80% !important;
	} 
}