.icon-tableau-financier:before {
  content: "\e930";
}

.icon-tableau-strategique:before {
  content: "\e931";
}

.icon-tableau-organisme:before {
  content: "\e938";
}

.icon-suivi-conso:before {
  content: "\e942";
}

.container.extended {
  width: fit-content;
  min-width: 100%;

  .basic-table {
    width: auto;

    .blocCriteres > .table-expander tbody, .blocCriteres .table-tableau .table-expander.ventilation tbody {
      tr:last-child td:last-child:after {
        width: 100%;
        bottom: 41px;
        left: 0px;
      }

      tr > th:first-child {
        min-width: 150px;
      }
    }
  }
}

.basic-table .blocCriteres {
  .has-total tbody tr:last-child {
    border-top: 2px solid @CouleurBlack;

    > td, th {
      font-weight: 700;

      .wrapper:hover > .libelle-hover {
        display: none;
        text-transform: initial;
      }
    }

    td:first-child {
      text-align: end;
    }
  }

  .has-total-pilotage tbody tr:last-child {
    border-top: 2px solid @CouleurBlack;

    > td, th {
      background: @CouleurTableauPilotageTotal;
      font-weight: 700;

      .wrapper:hover > .libelle-hover {
        display: none;
        text-transform: initial;
      }
    }

    td:first-child {
      text-align: end;
    }
  }

  .table-expander.has-total, table-expander.has-total-pilotage tbody tr:last-child {
    border-top: none;

    td:last-child:after {
      background-color: @CouleurBlack;
      content: '';
      height: 2px;
      position: absolute;
      width: 1204px;
      left: 26px;
      bottom: 78px;
    }

    td:first-child {
      text-align: left;
    }
  }
}

.icone-export {
  width: 100%;

  th {
    padding: unset;
  }
}

.blocCriteres table.table-expander.quatre-niveau tbody tr {
  .tab-niv1 {
    background-color: @CouleurExpanderBackgroundNiv0;
  }

  .tab-niv2 {
    background-color: @CouleurExpanderBackgroundNiv1;
  }

  .tab-niv3 {
    background-color: @CouleurExpanderBackgroundNiv2;
  }

  .tab-niv4 {
    background-color: @CouleurBgWhite;
  }
} 