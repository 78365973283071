.button_bar{
  text-align: center;
  margin-top: 25px;
  .button{
    background:@CouleurBgGreen;
    color:@CouleurWhite;
    border: 1px solid @CouleurBorderGreen;
    padding: 10px 20px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 30px;
    text-align: center;
    display: inline-block;
    font-size: 0.875em ;
    font-weight:500;
    margin-right: 20px;

    &.editique {
      margin-top: 15px;
    }
	&:hover {
		cursor: pointer;
	}
  }
	
}
.green-btn{
  background:@CouleurBgGreen;
  color: @CouleurWhite;
  border: 1px solid @CouleurBorderGreen;
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  font-size: 0.875em ;
  font-weight:500 ;
  margin-right: 10px;
  margin-left: 10px;
}
.grey-btn, .grey-btn.button{
  background:@CouleurBorderGreyTable;
  color:@CouleurWhiteGrey;
  border: 1px solid @CouleurBorderGreyTable;
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  font-weight:500 ;
  font-size:0.875em;
  margin-right: 10px;
  margin-left: 10px;
}
/*left-bt : Pour les boutons retour à  la liste*/
.left-bt{
  text-align: left;
}
.right-bt{
  text-align: right;
  margin-top: 10px;
}

.button_bar .desactiver {
    pointer-events: none;
    background: @CouleurBorderGreyTable;
	border: 1px solid @CouleurBorderGreyTable;
}

table a.desactiver {
	cursor: unset;
	pointer-events: none;
	i{
		color: @CouleurBorderLightGrey;
	    vertical-align: top;
	}
	i:hover{
		color: @CouleurBorderLightGrey;	        			
	}
}
