.bloc-onglets(@width){
  .first-liste{
    list-style: none;
    padding:0;
    margin: 0;
    margin-left: 1px;
    display: table;
    width: 100%;
    li{
      display: table-cell;
      text-align: center;
      background: @CouleurBgWhite;
      width: @width;
      a{
        padding: 15px 16px;
        color:@CouleurMainBlue;
        display: block;
        font-weight: 500;
        font-size: 0.875em;
      	border: 1px solid @CouleurBorderGreyTitle;
        text-transform: uppercase;
        text-decoration: none;
      }
	  &.noncliquable:hover{
		background: @CouleurBgWhite;
		cursor: inherit;
		a{
			color:@CouleurMainBlue;
		}
	}
      &:hover{
        background: @CouleurBgGreen;
        transition: color .2s cubic-bezier(.65,.4,.29,1.01),background .2s cubic-bezier(.65,.4,.29,1.01),opacity .2s cubic-bezier(.65,.4,.29,1.01);
        a{
          color: @CouleurWhite;
        }
      }
       &.on{
        background: @CouleurBgGreen;
        a{
          color:@CouleurWhite;
        }
		&.noncliquable:hover a{
			font-weight: 500;
		}
        &:hover{
          background: @CouleurBgGreen;
          border: 0;
          a{
            color: @CouleurWhite;
            font-weight: 700;
          }
        }
      }
    }
  }
  .first-liste.alone {
	width: 314px;
	pointer-events: none;
}
  .second-liste{
    margin: 0;
    background: @CouleurBgGrey;
    border-bottom: 1px solid @CouleurBorderGreyTable;
    list-style: none;
    margin-left: 16px;
    padding: 0;
    li{
      display: inline-block;
      text-align: center;
      color:@CouleurMainBlue;
      a{
        text-decoration: none;
        padding: 15px;
        font-size: 0.938em;
        color:@CouleurMainBlue;
        display: inline-block;
      }
	  &.noncliquable:hover {
		border-bottom: none;
		cursor: inherit;
	  }
      &.on, &.on.noncliquable:hover{
        border-bottom: 5px solid @CouleurBorderGreen;
      }
      &:hover{
        border-bottom: 5px solid @CouleurBorderGreen;
      }
    }
  }
}

.bloc-onglets-5 {
	.bloc-onglets(@WidthBlocOnglet5);
	}
.bloc-onglets {
  .bloc-onglets(@WidthBlocOnglet);
  }
.bloc-onglets-4 {
  .bloc-onglets(@WidthBlocOnglet4);
  }
 .bloc-onglets-enveloppe {
  .bloc-onglets(@WidthEnveloppe);
  .first-liste li {
  	padding: 15px 16px;
    border: 1px solid @CouleurBorderGreyTitle;
    vertical-align: middle;
    span a {
    	padding:unset;
    	border: none;
    	}
  	}
  }
.bloc-onglets-organisme {
  .bloc-onglets(@WidthBlocOnglet6);
  .first-liste li {
  	padding: 15px 16px;
    border: 1px solid @CouleurBorderGreyTitle;
    vertical-align: middle;
    span a {
    	padding:unset;
    	border: none;
    	}
  	}
  }