.boutons-administration {
  width: 200px;
  margin-left: 0%;
  text-align: center;
  margin-top: -15px;

  a {
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;

    .item-img {
      display: inline-block;
      position: relative;
      font-size: 100px;
      padding: 30px;
      color: @CouleurGreyBlack;

      &.on, &.active, &:hover {
        color: @CouleurBgGreen;
      }
    }

    .green-btn {
      width: 170px;
    }

  }

}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-collaborateurs:before {
  content: "\e910";
}

.icon-actualites:before {
  content: "\e912";
}

.icon-referentiels-regionaux:before {
  content: "\e913";
}

.icon-natures-juridiques:before {
  content: "\e914";
}

.icon-cadre-de-financement:before {
  content: "\e915";
}

.icon-organismes:before {
  content: "\e916";
}

.icon-comites:before {
  content: "\e917";
}

.icon-conventions:before {
  content: "\e918";
}

.icon-comptes-porteurs:before {
  content: "\e919";
}

.icon-services:before {
  content: "\e920";
}

.icon-campagne-budgetaire:before {
  content: "\e923";
}

.icon-enveloppe-budgetaire:before {
  content: "\e924";
}

.icon-import:before {
  content: "\e925";
}

.icon-parametres:before {
  content: "\e928";
}

.icon-traitement-automatique:before {
  content: "\e929";
}

.icon-flux:before {
  content: "\e931";
}

.icon-faf:before {
  content: "\e932";
}

.icon-cgu:before {
  content: "\e933";
}

.icon-operations_masse:before {
  content: "\e934";
}

.icon-alertes_notifications:before {
  content: "\e935";
}

.icon-axes_strategiques:before {
  content: "\e936";
}

.icon-export:before {
  content: "\e937";
}

.icon-document_text:before {
  content: "\e996";
}