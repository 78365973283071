.errorPage{
	width: 1280px;
	margin: 0 auto;
  	background-size: cover;
  	background: url(../images/bg-error.png) no-repeat;
	height: 883px;
	  #content{
	  	   height: -webkit-fill-available;
	  	   height: -moz-available;
		 .title-error {
		 	font-size:1.5em;
		 	margin-left: 25%;
    		margin-top: 20%;
		 	.texte {
		 		text-align: center;		 		
   				 text-shadow: 1px 1px 1px @CouleurBorderGrey;
		 		.debut {
			 		color : @CouleurBtnBlue;
			 		padding-right: 8px;	
			 		float:left;
			 		margin-left:20px;	 		
		 		 	}
			 	.fin {
			 		color : @CouleurBgGreen;
			 		text-align: left;
			 		}
			 	}
		 	.logo {
		 		float:left;
		 		}
		 	}
		 .bloc-text {
		 	.titre {
		 		padding-top:0px;
		 		padding-bottom: 10px;
		 		}
		 	background:@CouleurBgWhite;
		 	margin:50px 200px;
		 	padding:10px;
		 	}
	 	}
	 	
	 	#errordetail{
	 		background:@CouleurBgWhite;
	 		padding:10px;
	 		}
}

