.blocLisere {
  > table.header-visualisation {
    padding: 0;

    td {
      text-align: center;
      color: #39444f;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  > table.body-visualisation {
    // Colonne pr�sent
    padding-top: 0;

    tr {

      td:nth-child(4) {
        span.present {
          border: 5px solid black;
        }
      }

      td:nth-child(1), td:nth-child(4), td:nth-child(7) {

        span {
          display: flex;
          padding: 5px;
          text-align: center;
          align-items: center;
          height: 80px;

          &.niv-1 {

            background-color: @CouleurExpanderBackgroundNiv0;
          }

          &.niv-2 {

            //  height: 100px;
            background-color: @CouleurExpanderBackgroundNiv1;
          }

          &.niv-3 {

            // height: 150px;
            background-color: @CouleurExpanderBackgroundNiv2;
          }
        }
      }

      td:nth-child(2), td:nth-child(5) {
        text-align: left;
      }

      td:nth-child(3), td:nth-child(6) {
        text-align: right;
      }
    }
  }
}