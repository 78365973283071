.pdf {
	#header{
		height: 140px;
	  position: running(header);
	}
	background-color: @CouleurWhite;
	width: 26.7cm;
	.contenu-page {
		padding: 0;
	    width: 100%;
		background-color: @CouleurWhite;
		color: @CouleurWhite;
	}
 	.contenu-page.basic-table table {
		border-collapse: separate;
		padding: 0px;
		th, td, th.horstable {
			font-size: 0.7em;
			padding: 10px 10px;
		}
	}
	
	.bloc-tableau_entete{
		margin-top : 30px;
		width: 100%;
		td {
          background-color: @CouleurBtnBlue !important;
          text-transform: uppercase;
          font-weight: 700;
          padding: 10px 10px;
      	}
		td:last-child{
			white-space: nowrap;	
		}
	}
	.logo{
	    margin-left: 25px;
    	margin-top: 50px;
    	margin-right: 50px;
    	float: left;
	}
	.titre{
	  text-align: center;
	  color: @CouleurBtnBlue;
	  font-size: 1.375em;
	  font-weight: 700;
	  padding-top: 37px;
	  padding-bottom: 10px;
	}
	.soustitre{
	  color:@CouleurMainBlue;
	  font-size: 1.2em;
	  font-style: italic;
	  padding-bottom: 15px;
	  padding-left: 30px;
	  font-weight: lighter;
	  text-align: center;
	}
}

.pv-comite .blocCriteres{
	td:nth-child(7), td:nth-child(8){
		white-space: nowrap;			
	}
} 
@page pv-comite{
	size: a4 landscape;
	margin-top: 135px;
	 @top-center {
	    content: element(header);
	 }
	 @bottom-right {
	    content: counter(page) "/" counter(pages);
		font-family: 'roboto';
	 }	
}
.pdf.pv-comite{
	page:pv-comite;
}
@page odj-comite{
	size: a4 landscape;
	margin-top: 135px;
	margin-left:3px;
	margin-right:3px;
	@top-center {
	    content: element(header);
	  }
	 @bottom-right {
	    content: counter(page) "/" counter(pages);
		font-family: 'roboto';
	  }	
}
.pdf.odj-comite{
	.contenu-page.basic-table table {
		th, td, th.horstable {
			font-size: 5.5px;
		}
		.kss_nombre_dec_kss{
			white-space: nowrap;	
		} 
	}
	width: 29cm;
	page:odj-comite;
}