
@page land {
	size: a4 landscape;
}

@page port {
	size: portrait;
}

@media print {

html, body {
	background: @CouleurBgWhite !important;
}
	
body.landscape {
	page: land;
	height: 16.5cm; 
	width: 26cm;
}

.portrait {
	page: port;
}

  table, basic-table, advanced-table {
  	page-break-after:auto;
  	max-width: max-content;
  	horstable, tr, td, th {
  		background: @CouleurBgWhite !important;
   		}
   	}
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }

	.menu-gauche-deroulant, .bloc-onglets, .entete, .warningMessage, .tooltip, a.button, .material-icons, .toolbar  {
		display :none !important;
	}
	.nav-and-content {
		width: 930px !important;
		margin: 0 auto !important;
		text-align: left !important;
		background: @CouleurBgWhite;
	}
	.infoModification {
		display :none !important;
		color : transparent !important;
	}	
	.footer {
	    vertical-align: bottom;
	    min-height: -webkit-fill-available;
	    .area-logos{
    		float: left;
   		 }
	}
	.titre-entete {
		border:0;
		}
	form {
		margin: 0;
		padding: 0;
	}
	.page-break-after{
		page-break-after: always;
	}
	.page-break-before{
		page-break-before: always;
	}
}