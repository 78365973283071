.blocLisere, .blocCriteres, .blocMontant {
  caption {
    text-align: left;
    color: @CouleurBtnBlue;
    font-size: 1.375em;
    font-weight: 700;
    padding-top: 37px;
  }

  > table.without-border {
    border: none;
  }

  > table.without-padding-bottom {
    padding-bottom: 0;
  }

  > table.without-padding-top {
    padding-top: 0;
  }

  > table, .ventilation {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;

    thead {
      th {
        &.horstable {
          background: @CouleurBgGrey;
          border: 0;
          text-align: right;
          text-transform: capitalize;
          font-weight: 700;
          padding: 0 0 21px 0;
        }
      }
    }

    tbody {
      tr {
        td {

          padding-top: 4px;
          padding-bottom: 4px;

          span.champ-rempli {
            padding: 9px 20px;
            width: 368px;
            color: @CouleurBlueDark;
            display: inline-block;
          }

          input {
            padding: 9px 17px;
            width: 375px;
            color: @CouleurBlueDark;
            margin-right: 30px;
          }

          //date
          .short-area {
            input {
              &.demi_cellule_2 {
                width: 55%;
              }
              width: 75%;
              max-width: 340px;
              margin-right: 15px;
            }

            img.ui-datepicker-trigger {
              vertical-align: middle;
              margin: 0;
            }

            a {
              .icon.search {
                vertical-align: middle;
                display: inline-block;
              }
            }

            .yui-ac-container {
              position: absolute;
              z-index: 1;
            }
          }

          //radio
          .inputRadioGroup {
            padding: 9px 20px;
            color: @CouleurBlueDark;
            display: inline-block;

            input {
              width: auto;
              margin-right: 5px;
              padding: 0;
            }
          }

          //checkbox
          input.checkbox {
            width: auto;
            padding: 0;
          }

          //select area
          .ui-selectmenu-button {
            width: -moz-available; /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
            max-width: 373px;
            background: @CouleurBgWhite;
            padding: 7px 20px 6px 17px;
            border: 1px solid @CouleurBorderGrey;
            font-size: 0.938em;
            display: inline-block;
            margin-right: 30px;

            span {
              padding: 0;
            }

            &.margin-right-increase .ui-selectmenu-text {
              margin-right: 28px;
            }
          }

          textarea {
            width: 372px !important;
            background: #fff;
            padding: 7px 20px 6px 17px;
            border: 1px solid #c2c2c2;
            font-size: 0.938em;
            display: inline-block;
            margin-right: 30px;
            font-family: roboto;
            color: #253858;

            line-height: 1.7;
            max-height: 100px;
          }

          .expandable {
            height: 28px;
          }

          .countMsg {
            font-size: 0.875em;
            text-align: right !important;
          }

          .error {
            .ui-selectmenu-button, textarea, input.checkbox, .inputRadioGroup, input {
              background: @Error-background;
              border: 1px solid @Error-border;
              margin-right: 5px;
            }
          }
        }

        &.ligne-commune {
          th {
            display: block;
            padding: 12px 0px;
          }
        }
      }
    }

    &.bloc-formulaire, & {
      position: relative;

      caption {

        top: 0px;
      }

      tbody {
        tr {
          th {
            text-align: right;
            padding-right: 21px;
            font-weight: normal;
            width: 615px;
          }
        }
      }
    }

    &.tab_form, &.table-donnee {
      caption {
        position: static;
      }

      tbody {
        tr {
          text-align: left;
          text-transform: initial;

          th, td {
            color: @CouleurMainBlue;
            font-size: 0.875em;
            font-weight: 500;
            text-align: left;
          }
        }
      }
    }

    //empty table
    &.list-empty {
      margin-top: 21px;

      tr th {
        border: 0 !important;
        background: @CouleurBgWhite;
      }
    }

    &.zone-date {
      tr {
        td {

          .short-area {
            .date-search {
              width: 300px;
            }
          }
        }

        th {
          text-align: right;
          width: 170px;
        }
      }
    }

  }

  //TODO
  &.table-donnee {
  }

  &.blocLisere {
    > table tbody tr th {
      color: @CouleurMainBlue;
      font-size: 0.875em;
      font-weight: 500;
    }
  }

  &.blocCriteres {
    > table tbody tr, .table-tableau table.ventilation tbody tr {
      color: @CouleurMainBlue;
      font-size: 1em;
      font-weight: 500;
    }
  }
}


.blocMontant {
  padding-left: 12px;

  table {

    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;

    tbody tr td.kss_annee_kss, thead tr th {

      text-align: right;
      padding-right: 21px;
    }
  }
}
