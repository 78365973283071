.boutons-ordonnancement {
  width: 200px;
  margin-left: 0%;
  text-align: center;
  margin-top: -15px;

  a {
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;

    .item-img {
      display: inline-block;
      position: relative;
      font-size: 100px;
      padding: 30px;
      color: @CouleurGreyBlack;

      &.on, &.active, &:hover {
        color: @CouleurBgGreen;
      }
    }

    .green-btn {
      width: 170px;
    }

  }

}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ordonnancement:before {
  content: "\e939";
}

.icon-notification:before {
  content: "\e940";
}

.icon-liquidation:before {
  content: "\e941";
}
