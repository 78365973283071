.colonne-centrale {
  border-top: 1px solid @CouleurBorderGreyTable;

  a, span {
    color: @CouleurMainBlue;
  }

  &.user-accueil {
    .titre {
      padding-bottom: 14px;
      padding-left: 0;
      padding-top: 16px;
    }

    .contenu-page {
      padding-right: 0;
    }
  }

  .boutons-centre {
    padding-bottom: 20px;
    text-align: center;

    a, span {
      color: @CouleurWhite;
    }
  }

  .accueil-etapes {
    table {
      border: 0;
      padding-left: 47px;
      padding-right: 47px;

      tr {
        th {
          font-size: 0.938em;
          width: auto;
          text-align: left;
          font-weight: 500;
        }

        td {
          .ui-selectmenu-button {
            width: 247px !important;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.colonne-droite {
  &.position-bloc {
    float: left;
    width: 550px;

    .titre {
      padding: 0;
      margin: 0;
    }
  }
}

.colonne-gauche {
  float: left;
  width: 565px;
  margin-right: 63px;
}

//pour les blocs
.titre-bloc {
  font-size: 1.625em;
  color: @CouleurMainBlue;
  font-weight: normal;
  margin-bottom: 15px;
  margin-top: 16px;

  a {
    text-decoration: none;
    font-size: 1em;
  }
}

.static-bloc {
  border: 1px solid @CouleurBorderLightGrey;
  background: @CouleurBgWhite;
  border-bottom: 5px solid @CouleurBorderBlue;
  margin-bottom: 45px;
  
}

.colonne-centrale .accueil-etapes.accueil-projets-a-traiter {
  &.static-bloc {
    border: 0;
    background: transparent;
  }

  table {
    padding: 0;

    tbody {
      tr:first-child {
        border-top: 1px solid @CouleurBorderGreyTable;
      }

      tr {
        display: block;
        font-size: 1.125em;
        background: @CouleurBgWhite;
        border-left: 3px solid @CouleurBorderBlue;
        border-bottom: 1px solid @CouleurBorderGreyTable;
        border-right: 1px solid @CouleurBorderGreyTable;
        padding: 6px 8px 8px;
        font-weight: normal;
        text-align: left;

        td {
          > span {
            display: block;
            font-weight: bold;
            color: @CouleurGreen;
            float: left;
            width: 119px;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}


.basic-bloc {
  border: 1px solid @CouleurBorderLightGrey;
  background: @CouleurBgWhite;
  margin-bottom: 45px;
  border-bottom: 5px solid @CouleurBorderBlue;

  .container-actus {
    border-bottom: 1px solid @CouleurBorderGreyTable;

    &:last-child {
      border-bottom: 0;
    }

    padding: 30px 35px;

    .kss_date_kss {
      color: @CouleurGreyBlack;
      font-size: 0.875em;
      display: block;
      margin-bottom: 17px;
    }

    .kss_libelle_long_kss {
      color: @CouleurGreyBlack;
      font-size: 0.875em;
      line-height: 23px;
      margin-bottom: 15px;
    }

    .kss_texte_long_riche_kss {
      p, ul li {
        color: @CouleurGreyBlack;
        font-size: 0.875em;
        line-height: 23px;
        margin-bottom: 15px;
      }
    }

    a {
      text-transform: uppercase;
      color: @CouleurGreyDate;
      font-size: 0.75em;
      display: block;
      font-weight: 700;
    }
  }
}