//borderPage
.borderTop{
  background: url("../images/border-top.jpg");
  height: 5px;
}

/*login page*/
.connexion {
  background: url(../images/connexion-stars-fir.png) no-repeat;
  background-size: cover;
  width: 1600px;
  margin: 0 auto;
  .module-connexion{
    height: 700px;
    //content
    .module-centre{
      margin-left: 159px;
      padding-top: 160px;
      .label-connexion{
        font-size: 1.563em;
        color: @CouleurBlack;
        text-transform: uppercase;
        margin-left: 10px;
      }
	  //logo stars-fir
	  h1.logo-stars-fir{
		font-size: 2.6em;
    	font-weight: 400;
		span {
			color: @CouleurWhite;
		    font-weight: 500;
		}
	  }
      //champs login
      .champs{
        margin-top: 66px;
        a{
          color: @CouleurWhite;
        }
        .champs_inner{
          margin-left: 10px;
          width: 417px;
          .ligne{
            border: 1px solid @CouleurBorderGrey;
            background: @CouleurBgWhite!important;
            margin-bottom: 14px;
            width: 415px;
            &.area-pw{
              .picto{
                margin-right: 11px;
              }
            }
            .picto{
              margin-left: 16px;
              margin-right: 7px;
              vertical-align: middle;
            }
            input{
              color:@CouleurMainBlue;
              font-weight: 500;
              padding: 14px;
              padding-left: 7px;
              height: 14px;
              border:0;
              width: 85%;
            }
          }
        }
      }
      //link
      .lien{
        a{
          text-decoration: none;
          text-shadow: 6px 5px 25px rgba(0, 0, 0, 0.75);
          color:@CouleurBlack;
          &:first-child{
            margin-right: 46px;
          }
          &:before{
            content: "";
            width: 5px;
            height: 8px;
            display: inline-block;
            margin-right: 2px;
            vertical-align: middle;
          }
        }
      }
      //bouton connexion
      .bouton-login{
        text-align: center;
        a.btn-connexion{
          color:@CouleurWhite;
          span{
            text-transform: uppercase;
            text-decoration: none;
            margin-top: 45px;
            display: inline-block;
            background: @CouleurBtnBlue;
            padding: 18px 29px;
            border-radius: 36px;
            font-weight: 700;
            border: 1px solid @CouleurBorderBlue;
          }
        }
      }
      //Réinitialisation du mot de passe
      .popin-new.popin-new-small{
        width: 417px;
        .button_bar{
          .left-bt, .right-bt{
            display: inline-block;
            margin-right: 20px;
          }
        }
      }
    }
  }
  //bottom-of-page
  .logos-bas{
    height: 212px;
    background: transparent;
    padding: 0;
    .logos-bottom{
      text-align: right;
      padding: 60px;
    a.ministere img {
		border:1px solid #a1a1a1;
	}
	a{
        margin-right: 24px;
      }
    }
  }
  //msg
  .error, .warningMessage, .infoMessage{
    margin: 0;
    width: 80%;
  }
  .error{
    color: @CouleurRedDark;
    background-color: @CouleurBgPink;
    padding: 23px;
    margin-bottom: 10px;
    border: 1px solid @CouleurBorderPink;
    border-radius: 6px;
    text-align: left;
    margin-top: 10px;
  }
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: left;
}


