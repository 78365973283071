
.basic-table{
  width: 1205px;
  background: @CouleurBgGrey;
  .blocCriteres{
  	
    table{
      background: @CouleurBgWhite;
      border-collapse:collapse;
      border:0;
	  width : 100%;

      &.export {
        width: 82%;
      }
      caption{
        position: static;
        padding: 28px 0 28px 12px;
      }
      
      thead{
        th{
          color: @CouleurEnteteTable;
          text-transform: uppercase;
          font-size: 0.875em;
          font-weight: 700;
          padding: 15px;
          text-align: left;
          border: 1px solid @CouleurBorderGreyTitle;
          position: relative;

		  .table-tooltip {
			display: flex;
			align-items: center;
			.tooltiptext {
				text-transform: none;
			}
		  }

          a{
            font-size:1em;
            text-decoration: none;
            font-weight: 700;
            display: inline-block;
    		width: 100%;
            
          }
          
          &.horstable{
            background: @CouleurBgGrey;
            border: 0;
            text-align: right;
            text-transform: capitalize;
            font-weight: 700;
            padding: 5px 0 15px 0;
            
            .left {
            	float: left;
            }
            .right {
            	float: right;
				a{
					display: inline;
				}
            }
          }
        }
      }
      tbody{
        tr{
          background: @CouleurContentTable;
          &.odd{
            background: @CouleurBgWhite;
          }
		  .kss_nombre_dec_kss, .kss_montant_kss, .kss_montant_diff_kss {
			text-align: right;
		}
          td, th{
            text-align: left;
            max-width: 100%;
            border:1px solid @CouleurBorderGreyTitle;
            font-size: 0.875em;
            padding: 11px;
            .material-icons{
            	text-align: center;
			    width: 100%;
			    padding: 0px;
            }
          }
		  td.center{
			text-align: center;
		  }	
		  td.right{
			text-align: right;
			span{
				margin-right: 0;
			}
		  }	
        }
      }
      //empty table
      &.list-empty {
        margin-top: 21px;
        tr th {
          border: 0!important;
          background: @CouleurBgWhite;
        }
      }
      &.tab-ajout-content{
        margin-top: 20px;
        margin-bottom: 20px;
        tr{
          background: @CouleurBgWhite;
          td{
            border: 0;
            padding: 11px 21px;
          }
        }
      }
    }
  }
}
.basic-table  .blocCriteres  table.iconeTri > thead > tr > th {
	.sortAsc:after{
	content:"expand_more";
	}
	.sortDesc:after{
		content:"expand_less";
	}
	> a {
		
		padding-right: 16px;
	}
    > a:after{
    	content:"unfold_more";
    	font-family: 'Material Icons';
		  font-weight: normal;
		  font-style: normal;
		  font-size: 22px;  /* Preferred icon size */
		  display: inline-block;
		  line-height: 1;
		  text-transform: none;
		  letter-spacing: normal;
		  word-wrap: normal;
		  white-space: nowrap;
		  direction: ltr;
		  position:absolute;
		  padding-left: 0;
		  top: 37%;
		  right: 0;
		
		  /* Support for all WebKit browsers. */
		  -webkit-font-smoothing: antialiased;
		  /* Support for Safari and Chrome. */
		  text-rendering: optimizeLegibility;
		
		  /* Support for Firefox. */
		  -moz-osx-font-smoothing: grayscale;
		
		  /* Support for IE. */
		  font-feature-settings: 'liga';
   		 }

}
.basic-table .blocCriteres > table.parAnnee tbody{
	
	tr:nth-child(2n+1){
		background: @CouleurBgWhite;
	}
	tr {
		td {
			input,.ui-selectmenu-button{
				width: 80% !important;
				
			}
		}
		td.enTeteGrid{
			background: @CouleurBgWhite;
			text-transform: uppercase;
		}  
	} 
}