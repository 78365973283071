.tab-projet-sous-jacents .blocCriteres table tbody tr{
	background-color: @CouleurContentTable;
	&.odd{
            background: @CouleurBgWhite;
          }
    .total.kss_libelle_long_kss > {
    text-align:right;
    }
    .total.kss_libelle_court_kss > {
    text-align:right;
    }
}