.advanced-table .blocCriteres{
  table{
    background: @CouleurBgWhite;
    border-collapse:collapse;
    border:0;
    caption{
      position: static;
	  padding-top: 40px;
      margin-bottom: 30px;
    }
    thead{
      th{
        color: @CouleurEnteteTable;
        text-transform: uppercase;
        font-size: 0.875em;
        font-weight: 700;
        padding: 21px 18px;
        text-align: left;
        border: inherit;

        a{
          font-size:1em;
          text-decoration: none;
          font-weight: 700;
        }
        
        &.horstable{
          background: @CouleurBgGrey;
          border: 0;
          text-align: right;
          text-transform: capitalize;
          font-weight: 700;
          padding: 0 0 21px 0;
        }
      }
    }
    tbody{
      tr{
        background: @CouleurBgWhite;
        &.odd{
          background: @CouleurBgWhite;
        }
        td.kss_montant_kss, td.kss_montant_diff_kss{
        	white-space: nowrap;
    	}
    	.kss_nombre_dec_kss, .kss_montant_kss, .kss_montant_diff_kss {
        	 text-align: left;
       	}
        td, th{
          text-align: left;
          max-width: 100%;
          font-size: 0.875em;
          padding: 11px;
        	border: inherit;
        	 

			&.sous-total-background {
	      		background: @CouleurBgGrey;
	      	}
			&.sous-total {
	      		background: @CouleurBgGrey;
	      		font-weight: 700;     		
	      	}
	      	&.total {
	      		background: @CouleurBgGrey;
	      		font-weight: 700;
	      		border-top: 1px solid @CouleurBlack;
	      	}
        }
      }
    }
    //empty table
    &.list-empty {
      margin-top: 21px;
      tr th {
        border: 0!important;
        background: @CouleurBgWhite;
      }
    }
    &.tab-ajout-content{
      margin-top: 20px;
      margin-bottom: 20px;
      tr{
        background: @CouleurBgWhite;
        td{
          border: 0;
          padding: 11px 21px;
        }
      }
    }
  }
}