/**
 * @file
 * Default Html Tags Styling
 */

html {
  font-size:1em;
  font-family: 'roboto';
  font-weight: normal;
}
.developpement > body {
  background: @ColorEnvBackgroundDeveloppement;
}
.recette > body {  
  background: @ColorEnvBackgroundRecette;
}
.recette.interne > body {  
  background: @ColorEnvBackgroundRecetteInterne;
}
.recette.interne.\32 > body {
  background: @ColorEnvBackgroundRecetteInterne2;
}
.preproduction > body {
  background: @ColorEnvBackgroundPreproduction;
}
.formation > body {
  background: @ColorEnvBackgroundFormation;
}
.validation > body {
  background: @ColorEnvBackgroundValidation;
}
body {
  font-size:1em;
  line-height: normal;
  color: @CouleurMainBlue;
  margin: 0;
  font-family: 'roboto';
  font-weight: normal;
  background: @CouleurBgGrey;
  > div.contenu-page-min{
		background: @CouleurBgGrey;
		height: -webkit-fill-available;
		> form {
			background: @CouleurBgGrey;
		}
	}
	> div.container {
		background: @CouleurBgGrey;
		margin: 0 auto;
    	position: relative;
   		width: 1220px;
        padding: 0 35px 37px 25px;
	        
	}
}
a{
  color: @CouleurMainBlue;
  font-size: 0.875em;
  font-weight: 500;
	&.disabled{
		pointer-events:none;
		cursor : default;
	}
}
.contenu-page{
  width: 1280px;
  margin: 0 auto;
  position: relative;
}
.contenu-page-min{
  width: 630px;
  margin: 0 auto;
  position: relative;
}
//title page
.titre{
  text-align: left;
  color: @CouleurBtnBlue;
  font-size: 1.375em;
  font-weight: 700;
  padding-left: 12px;
  padding-top: 37px;
  padding-bottom: 29px;
}

//liste en hover
ul li:hover{
  cursor: pointer;
}
.entete-global{
  border-top: 1px solid @CouleurBorderGreyTitle;
  border-left: 1px solid @CouleurBorderGreyTitle;
  border-right: 1px solid @CouleurBorderGreyTitle;
}
//global titre
.titre-entete{
  color:@CouleurMainBlue;
  font-size: 1.500em;
  padding-top: 23px;
  background: @CouleurBgWhite;
  padding-left: 16px;
}

.infos-annexes{
  display: inline;
  color:@CouleurMainBlue;
  font-size: 0.9em;
  padding-bottom: 15px;
  background: @CouleurBgWhite;
  padding-left: 0;
  font-weight: lighter;
}

//sous-tite ent�te
.subtitle{
  color:@CouleurMainBlue;
  font-size: 1.2em;
  font-style: italic;
  padding-bottom: 15px;
  background: @CouleurBgWhite;
  padding-left: 30px;
  border-left: 1px solid @CouleurBorderGreyTitle;
  font-weight: lighter;
}
body > .contenu-page {
	background-color: @CouleurBgGrey;
}
//content page
.contenu-page{
  padding: 0.5px 25px 37px 25px;
  .list-empty{
    caption{
      text-align: left;
    }
  }
  .warningMessage{
    margin-left: 0;
  }
}
//warning error
.warningMessage{
  margin: 20px 25px;
}
//page liste projet
.padding-page{
  padding: 24px;
}

//message error
form > .error{
  padding: 23px;
  border-radius: 6px;
  text-align: left;
  border-left: 6px solid @Error-border !important;
  background-color: @Error-background !important;
  margin-left : 16px;
  table{
    border: 0;

    th {
      white-space: nowrap;
    }
    td{
      padding: 0!important;
      font-size: initial!important;
      border: 0!important;
    }
  }
}
.contenu-page-haut,
.contenu-page,
.contenu-page-min, .area-search {
  > .error{
    padding: 23px;
    border-radius: 6px;
    text-align: left;
    border-left: 6px solid @Error-border !important;
    background-color: @Error-background !important;
    margin-left: 0;
  }
}

//pager
.horstable{
  .textligneliste {
      display: inline-block;
      padding: 10px;
      text-decoration: none;
  }
}

/*warning message*/
.warningMessage{
  padding: 23px;
  border-radius: 6px;
  text-align: left;
  border-left: 6px solid @Warning-border !important;
  background-color: @Warning-background !important;
  margin-left : 16px;
}
/*infoMessage*/
.infoMessage{
  padding: 23px;
  background-color: @Info-background;
  border-left: 6px solid @Info-border;
  border-radius: 6px;
  text-align: left;
}

.quitter{
  clear: both;
}

/*Permettre l'affichage des textes cke et sous-titres*/
.cke_chrome {
    margin-top: 47px;
}

.kss_texte_long_riche_kss  {
	p {
    	margin-top: 30px;
    }
}

.kss_texte_long_kss, .kss_message_riche_kss, .kss_texte_long_riche_kss {
	overflow-wrap: anywhere;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: @tooltip-background;
    color: @tooltip-color;
    text-align: center;
    padding: 4px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 105%;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    font-family: 'roboto';
    font-weight: 500;
}

/* Tooltip text */
.tooltip .tooltiptext-light {
    visibility: hidden;
    width: fit-content;
    background-color: #edf1f7;
    color: @CouleurGreyBlack;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 105%; 
    
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 10s;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext-light {
    visibility: visible;
    opacity: 0.5;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

form {
	.blocLisere, .blocCriteres {
		border-bottom: 1px solid @CouleurBorderGreyTable;
		padding-bottom: 30px;
	}
	.popin .blocLisere, .popin .blocCriteres {
		border-bottom: none;
		padding-bottom: 0;
	}
}

div.ui-helper-hidden-accessible, .hidden-node {
	display: none;
}

div.tox .tox-editor-header {
  z-index:1;
}


